import React from 'react';
import { List, Edit, Create, Filter, Datagrid, ReferenceField, TextField, EditButton, 
  DisabledInput, ReferenceInput, SimpleForm, TextInput, BooleanInput, BooleanField } from 'react-admin';

const DeviceFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
        <TextInput optionText="primaryUserId" />
      </ReferenceInput>
    </Filter>
);

export const DeviceList = (props) => (
  <List {...props} filters={<DeviceFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Primary User" source="userId" reference="primaryUsers">
        <TextField source="primaryUserId" />
      </ReferenceField>
      <TextField source="token" />
      <TextField source="platform" />
      <TextField source="osVersion" />
      <TextField source="locale" />
      <TextField source="name" />
      <BooleanField source="enabled" />
      <TextField source="errorMsg" />
      <EditButton />
    </Datagrid>
  </List>
);

const DeviceTitle = ({ record }) => {
    return <span>Device {record ? `"${record.token}"` : ''}</span>;
};

export const DeviceEdit = (props) => (
    <Edit title={<DeviceTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
          <TextInput source="primaryUserId" />
        </ReferenceInput>
        <TextInput source="token" />
        <TextInput source="platform" />
        <TextInput source="osVersion" />
        <TextInput source="locale" />
        <TextInput source="name" />
        <BooleanInput source="enabled" />
        <TextInput source="errorMsg" />
      </SimpleForm>
    </Edit>
);

export const DeviceCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
          <TextInput source="primaryUserId" />
        </ReferenceInput>
        <TextInput source="token" />
        <TextInput source="platform" />
        <TextInput source="osVersion" />
        <TextInput source="locale" />
        <TextInput source="name" />
        <BooleanInput source="enabled" />
        <TextInput source="errorMsg" />
      </SimpleForm>
    </Create>
);
