import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerRestClient from 'ra-data-json-server';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import StorageIcon from '@material-ui/icons/Storage';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ExposurePlus1 from '@material-ui/icons/ExposurePlus1';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ExtensionIcon from '@material-ui/icons/Extension';

import authClient, { baseUrl } from './authClient';
import Dashboard from './Dashboard';
import { SegmentList, SegmentEdit, SegmentCreate } from './segments';
import { InstrumentList, InstrumentEdit, InstrumentCreate } from './instruments';
import { AlertList, AlertEdit, AlertCreate } from './alerts';
import { PrimaryUserList, PrimaryUserEdit, PrimaryUserCreate } from './primaryUsers';
import { DeviceList, DeviceEdit, DeviceCreate } from './devices';
import { ApiClientList, ApiClientEdit, ApiClientCreate } from './apiClients';
import { InstrumentSuggestionList } from './instrumentSuggestions';
import { BrokerList, BrokerEdit, BrokerCreate } from './brokers';
import { FeatureList, FeatureEdit, FeatureCreate } from './features';
import { EnvironmentList, EnvironmentEdit, EnvironmentCreate } from './environments';
import { TagList, TagEdit, TagCreate } from './tags';
import { PrimaryInstrumentList, PrimaryInstrumentEdit } from './primaryInstruments';

const httpClient = (url, options = {}) => {
  options.user = {
    authenticated: true,
    token: localStorage.getItem('token')
  }
  return fetchUtils.fetchJson(url, options);
}
export const restClient = jsonServerRestClient(baseUrl, httpClient);

export default () => (
  <Admin title="Talaris" authProvider={authClient} dataProvider={restClient} dashboard={Dashboard}>
    <Resource name="brokers" list={BrokerList} edit={BrokerEdit} create={BrokerCreate} icon={AccountBalanceIcon} />
    <Resource name="segments" list={SegmentList} edit={SegmentEdit} create={SegmentCreate} icon={BubbleChartIcon} />
    <Resource name="configInstruments" list={InstrumentList} edit={InstrumentEdit} create={InstrumentCreate} icon={ShowChartIcon} />
    <Resource name="primaryInstruments" list={PrimaryInstrumentList} edit={PrimaryInstrumentEdit} icon={ShowChartIcon} />
    <Resource name="tags" list={TagList} edit={TagEdit} create={TagCreate} icon={StorageIcon} />
    <Resource name="alerts" list={AlertList} edit={AlertEdit} create={AlertCreate} icon={NotificationsIcon} />
    <Resource name="instrumentSuggestions" list={InstrumentSuggestionList} icon={ExposurePlus1} />
    <Resource name="environments" list={EnvironmentList} edit={EnvironmentEdit} create={EnvironmentCreate} icon={StorageIcon} />
    <Resource name="apiClients" list={ApiClientList} edit={ApiClientEdit} create={ApiClientCreate} icon={StorageIcon} />
    <Resource name="features" list={FeatureList} edit={FeatureEdit} create={FeatureCreate} icon={ExtensionIcon} />
    <Resource name="primaryUsers" list={PrimaryUserList} edit={PrimaryUserEdit} create={PrimaryUserCreate} icon={PersonIcon} />
    <Resource name="devices" list={DeviceList} edit={DeviceEdit} create={DeviceCreate} icon={PhoneIphoneIcon} />
  </Admin>
)
