// in src/Dashboard.js
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
// import ListenWebsocketButton from './ListenWebsocketButton';
import ClearCacheButton from './ClearCacheButton';
import JobButton from './jobs/JobButton';

export default () => (
  <Card>
    <Title title="Talaris Dash" />
    <CardContent>
      <ClearCacheButton />
    </CardContent>
    <CardContent>
      <JobButton url="primary/sync" params={{ id: "" }} title="Sync Primary Instruments"/>
      <JobButton url="primary/options/sync" params={{ id: "" }} title="Sync Primary Options"/>
      <JobButton url="dailyPrices/sync" params={{ id: "" }} title="Sync Daily Prices"/>
    </CardContent>
    <CardContent>
      <JobButton url="yahoo/profiles/sync" params={{ id: "" }} title="Sync Yahoo Profiles"/>
      <JobButton url="yahoo/marketInfos/sync" params={{ id: "" }} title="Sync Yahoo MarketInfo"/>
      <JobButton url="yahoo/incomeStatements/sync" params={{ id: "" }} title="Sync Yahoo Income Statements"/>
      <JobButton url="yahoo/balanceSheets/sync" params={{ id: "" }} title="Sync Yahoo Balance Sheets"/>
      <JobButton url="yahoo/cashFlows/sync" params={{ id: "" }} title="Sync Yahoo Cash Flows"/>
      <JobButton url="yahoo/statistics/sync" params={{ id: "" }} title="Sync Yahoo Statistics"/>
      <JobButton url="yahoo/earningsAnalysis/sync" params={{ id: "" }} title="Sync Yahoo Earnings Analysis"/>
    </CardContent>
  </Card>
);