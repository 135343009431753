import React from 'react';
import { List, Edit, Create, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

export const FeatureList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="releaseStage" />
      <TextField source="minVersioniOS" />
      <TextField source="minVersionAndroid" />
      <TextField source="testUsers" />
      <EditButton />
    </Datagrid>
  </List>
);

const FeatureTitle = ({ record }) => {
    return <span>Feature {record ? `"${record.name}"` : ''}</span>;
};

export const FeatureEdit = (props) => (
    <Edit title={<FeatureTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="name" />
        <TextInput source="releaseStage" />
        <TextInput source="minVersioniOS" />
        <TextInput source="minVersionAndroid" />
        <TextInput source="testUsers" />
      </SimpleForm>
    </Edit>
);

export const FeatureCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="releaseStage" />
        <TextInput source="minVersioniOS" />
        <TextInput source="minVersionAndroid" />
        <TextInput source="testUsers" />
      </SimpleForm>
    </Create>
);
