import React from 'react';
import { List, Edit, Create, Filter, 
  Datagrid, ReferenceField, TextField, EditButton, 
  DisabledInput, ReferenceInput, SelectInput, 
  SimpleForm, TextInput } from 'react-admin';

const InstrumentFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput label="Segment" source="segmentId" reference="segments" allowEmpty perPage={1000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="ticker" />
    </Filter>
);

export const InstrumentList = (props) => (
  <List {...props} filters={<InstrumentFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Segment" source="segmentId" reference="segments">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="ticker" />
      <TextField source="name" />
      <TextField source="yahooName" />
      <TextField source="optionPrefix" />
      <TextField source="currency" />
      <EditButton />
    </Datagrid>
  </List>
);

const InstrumentTitle = ({ record }) => {
    return <span>Instrument {record ? `"${record.name}"` : ''}</span>;
};

export const InstrumentEdit = (props) => (
    <Edit title={<InstrumentTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <ReferenceInput label="Segment" source="segmentId" reference="segments"  perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="ticker" />
        <TextInput source="name" />
        <TextInput source="yahooName" />
        <TextInput source="optionPrefix" />
        <TextInput source="currency" />
      </SimpleForm>
    </Edit>
);

export const InstrumentCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Segment" source="segmentId" reference="segments" perPage={100} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="ticker" />
        <TextInput source="name" />
        <TextInput source="yahooName" />
        <TextInput source="optionPrefix" />
        <TextInput source="currency" />
      </SimpleForm>
    </Create>
);
