import React from 'react';
import { List, Datagrid, ReferenceField, TextField } from 'react-admin';

export const InstrumentSuggestionList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Segment" source="segmentId" reference="segments">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="ticker" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
