import React from 'react';
import { List, Edit, Create, Filter, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

const PrimaryUserFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <TextInput source="primaryUserId" />
    </Filter>
);

export const PrimaryUserList = (props) => (
  <List {...props} filters={<PrimaryUserFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="primaryUserId" />
      <TextField source="hermesUserId" />
      <TextField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);

const PrimaryUserTitle = ({ record }) => {
    return <span>Primary User {record ? `"${record.name}"` : ''}</span>;
};

export const PrimaryUserEdit = (props) => (
    <Edit title={<PrimaryUserTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="primaryUserId" />
        <TextInput source="hermesUserId" />
        <TextInput source="email" />
        <DisabledInput source="created_at" />
        <DisabledInput source="updated_at" />
      </SimpleForm>
    </Edit>
);

export const PrimaryUserCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="primaryUserId" />
        <TextInput source="email" />
      </SimpleForm>
    </Create>
);
