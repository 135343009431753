import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification as showNotificationAction, CREATE } from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import { restClient } from '../App'

class JobButton extends Component {
	handleClick = () => {
		const { push, showNotification, url, params } = this.props;
		restClient(CREATE, url, params)
			.then(() => {
				showNotification(`Start running ${url}`);
				push('/');
			})
			.catch((e) => {
				console.error(e);
				showNotification('Error: It could not start the job', 'warning')
			});
	}

	render() {
		return <Button variant="outlined" color="primary" onClick={this.handleClick}>{this.props.title}</Button>;
	}
}

JobButton.propTypes = {
  url: PropTypes.string,
  params: PropTypes.object,
  title: PropTypes.string,
	push: PropTypes.func,
	showNotification: PropTypes.func,
};

export default connect(null, {
	showNotification: showNotificationAction,
	push: pushAction,
})(JobButton);