import React from 'react';
import { List, Edit, Create, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, 
  TextInput, BooleanInput, BooleanField } from 'react-admin';

export const ApiClientList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="identifier" />
      <TextField source="secret" />
      <TextField source="name" />
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

const ApiClientTitle = ({ record }) => {
    return <span>Api Client {record ? `"${record.name}"` : ''}</span>;
};

export const ApiClientEdit = (props) => (
    <Edit title={<ApiClientTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="identifier" />
        <TextInput source="secret" />
        <TextInput source="name" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Edit>
);

export const ApiClientCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
);
