import React from 'react';
import { List, Edit, Create, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

export const SegmentList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="type" />
      <TextField source="subtype" />
      <TextField source="underlyingTicker" />
      <TextField source="prefix" />
      <TextField source="suffix" />
      <TextField source="regex" />
      <TextField source="currency" />
      <TextField source="symbol" />
      <TextField source="preOpenTime" />
      <TextField source="openTime" />
      <TextField source="closeTime" />
      <TextField source="postNegotiationTime" />
      <EditButton />
    </Datagrid>
  </List>
);

const SegmentTitle = ({ record }) => {
    return <span>Segment {record ? `"${record.name}"` : ''}</span>;
};

export const SegmentEdit = (props) => (
    <Edit title={<SegmentTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="name" />
        <TextInput source="type" />
        <TextInput source="subtype" />
        <TextInput source="underlyingTicker" />
        <TextInput source="prefix" />
        <TextInput source="suffix" />
        <TextInput source="regex" />
        <TextInput source="currency" />
        <TextInput source="symbol" />
        <TextInput source="preOpenTime" />
        <TextInput source="openTime" />
        <TextInput source="closeTime" />
        <TextInput source="postNegotiationTime" />
        <TextInput source="timezone" />
      </SimpleForm>
    </Edit>
);

export const SegmentCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="type" />
        <TextInput source="subtype" />
        <TextInput source="underlyingTicker" />
        <TextInput source="prefix" />
        <TextInput source="suffix" />
        <TextInput source="regex" />
        <TextInput source="currency" />
        <TextInput source="symbol" />
        <TextInput source="preOpenTime" />
        <TextInput source="openTime" />
        <TextInput source="closeTime" />
        <TextInput source="postNegotiationTime" />
        <TextInput source="timezone" />
      </SimpleForm>
    </Create>
);
