import React from 'react';
import { List, Edit, Filter, 
  Datagrid, ReferenceField, TextField, EditButton, 
  DisabledInput, ReferenceInput, SelectInput, 
  SimpleForm, TextInput, ChipField, ReferenceArrayInput,
  ArrayField, AutocompleteArrayInput, SingleFieldList } from 'react-admin';

const PrimaryInstrumentFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput label="Segment" source="segmentId" reference="segments" allowEmpty perPage={1000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="category" />
    </Filter>
);

export const PrimaryInstrumentList = (props) => (
  <List {...props} filters={<PrimaryInstrumentFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Segment" source="segmentId" reference="segments">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="ticker" />
      <TextField source="name" />
      <TextField source="category" />
      <ArrayField source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

const PrimaryInstrumentTitle = ({ record }) => {
    return <span>Instrument {record ? `"${record.name}"` : ''}</span>;
};

export const PrimaryInstrumentEdit = (props) => (
    <Edit title={<PrimaryInstrumentTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <ReferenceInput label="Segment" source="segmentId" reference="segments"  perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="ticker" />
        <TextInput source="name" />
        <ReferenceArrayInput label="Tags" source="tagIds" reference="tags" allowEmpty perPage={200}>
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="yahooName" />
        <TextInput source="optionPrefix" />
        <TextInput source="currency" />
      </SimpleForm>
    </Edit>
);

