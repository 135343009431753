import React from 'react';
import { List, Edit, Create, Filter, Datagrid, ReferenceField, TextField, 
  EditButton, DisabledInput, ReferenceInput, SimpleForm, TextInput, BooleanInput, 
  DateInput, DateField, ChipField, NumberField } from 'react-admin';

const AlertFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
        <TextInput optionText="primaryUserId" />
      </ReferenceInput>
      <TextInput source="ticker" />
    </Filter>
);

export const AlertList = (props) => (
  <List {...props} filters={<AlertFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Primary User" source="userId" reference="primaryUsers">
        <TextField source="primaryUserId" />
      </ReferenceField>
      <TextField source="ticker" />
      <ChipField source="direction" />
      <ChipField source="type" />
      <NumberField source="targetValue" />
      <DateField source="firstSentDatetime" />
      <EditButton />
    </Datagrid>
  </List>
);

const AlertTitle = ({ record }) => {
    return <span>Alert {record ? `"${record.ticker}"` : ''}</span>;
};

export const AlertEdit = (props) => (
    <Edit title={<AlertTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
          <TextInput source="primaryUserId" />
        </ReferenceInput>
        <TextInput source="symbol" />
        <TextInput source="marketId" />
        <TextInput source="ticker" />
        <TextInput source="direction" />
        <TextInput source="type" />
        <TextInput source="targetValue" />
        <TextInput source="currency" />
        <TextInput source="cfiCode" />
        <BooleanInput source="enabled" />
        <DateInput source="firstSentDatetime" />
        <DateInput source="expirationDatetime" />
      </SimpleForm>
    </Edit>
);

export const AlertCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Primary User" source="userId" reference="primaryUsers">
          <TextInput source="primaryUserId" />
        </ReferenceInput>
        <TextInput source="symbol" />
        <TextInput source="marketId" />
        <TextInput source="ticker" />
        <TextInput source="direction" />
        <TextInput source="type" />
        <TextInput source="targetValue" />
        <TextInput source="currency" />
        <TextInput source="cfiCode" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Create>
);
