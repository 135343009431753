import React from 'react';
import { List, Edit, Create, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

export const EnvironmentList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="slug" />
      <TextField source="username" />
      <TextField source="key" />
      <TextField source="baseUrl" />
      <TextField source="websocketUrl" />
      <EditButton />
    </Datagrid>
  </List>
);

const EnvironmentTitle = ({ record }) => {
    return <span>Environment {record ? `"${record.name}"` : ''}</span>;
};

export const EnvironmentEdit = (props) => (
    <Edit title={<EnvironmentTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="slug" />
        <TextInput source="username" />
        <TextInput source="key" />
        <TextInput source="baseUrl" />
        <TextInput source="websocketUrl" />
      </SimpleForm>
    </Edit>
);

export const EnvironmentCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="slug" />
        <TextInput source="username" />
        <TextInput source="key" />
        <TextInput source="baseUrl" />
        <TextInput source="websocketUrl" />
      </SimpleForm>
    </Create>
);
