import React from 'react';
import { List, Filter, Edit, Create, Datagrid, TextField, EditButton, DisabledInput, SimpleForm, TextInput } from 'react-admin';

const InstrumentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const TagList = (props) => (
  <List {...props} filters={<InstrumentFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="count" />
      <EditButton />
    </Datagrid>
  </List>
);

const TagTitle = ({ record }) => {
    return <span>Tags {record ? `"${record.name}"` : ''}</span>;
};

export const TagEdit = (props) => (
    <Edit title={<TagTitle />} {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="name" />
        <DisabledInput source="count" />
      </SimpleForm>
    </Edit>
);

export const TagCreate = (props) => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
);
