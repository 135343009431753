import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification as showNotificationAction, DELETE } from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import { restClient } from './App'

class ClearCacheButton extends Component {
	handleClick = () => {
		const { push, showNotification } = this.props;
		restClient(DELETE, `config/cache`, { id: "" })
			.then(() => {
				showNotification('Start listening to webscoket');
				push('/');
			})
			.catch((e) => {
				console.error(e);
				showNotification('Error: It could not start listening', 'warning')
			});
	}

	render() {
		return <Button variant="outlined" color="primary" onClick={this.handleClick}>Clear cache</Button>;
	}
}

ClearCacheButton.propTypes = {
	push: PropTypes.func,
	showNotification: PropTypes.func,
};

export default connect(null, {
	showNotification: showNotificationAction,
	push: pushAction,
})(ClearCacheButton);